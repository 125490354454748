import React, { useEffect } from 'react';
import ReactGA from 'react-ga';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';


const Services = () => {
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  const { t } = useTranslation();
  
  return (
    <div className="container py-5">
      <Helmet>
        <title>{t('webDev')} - MackiTech Solutions</title>
        <meta
          name="description"
          content="MackiTech Solutions offers top-notch web development services. We create responsive, user-friendly websites tailored to your business needs."
        />
      </Helmet>
      <section className="hero-section text-center" id="web-dev">
        <div className="container">
          <h1>{t('webDev')}</h1>
          <p>
            {t('webDevDetail')}
          </p>
        </div>
      </section>
      <section className="service-details">
        <div className="container">
          <h2>{t('webDevTitle')}</h2>
          <p>
            {t('webDevTitleDetail')}
          </p>

          <div className="row">
            <div className="col-md-6">
              <h3>{t('customWebDev')}</h3>
              <p>
                {t('customWebDevDetail')}
              </p>
            </div>
            <div className="col-md-6">
              <img
                src="images/custom-website.jpg"
                className="img-fluid"
                alt={t('customWebDevDetail')}
              />
            </div>
          </div>

          <div className="row mt-5">
            <div className="col-md-6 order-md-2">
              <h3>{t('customEmail')}</h3>
              <p>
                {t('customEmailDetail')}
              </p>
            </div>
            <div className="col-md-6 order-md-1">
              <img
                src="images/custom-email.jpg"
                className="img-fluid"
                alt={t('customEmailDetail')}
              />
            </div>
          </div>

          <div className="row mt-5">
            <div className="col-md-6">
              <h3>{t('event')}</h3>
              <p>
                {t('eventDetail')}
              </p>
            </div>
            <div className="col-md-6">
              <img
                src="images/event.png"
                className="img-fluid"
                alt={t('eventDetail')}
              />
            </div>
          </div>
          <div className="row mt-5">
            <div className="col-md-6 order-md-2">
              <h3>{t('responsiveWebDesign')}</h3>
              <p>
                {t('responsiveWebDesignDetail')}
              </p>
            </div>
            <div className="col-md-6 order-md-1">
              <img
                src="images/responsive-design.jpg"
                className="img-fluid"
                alt={t('responsiveWebDesignDetail')}
              />
            </div>
          </div>

          <div className="row mt-5">
            <div className="col-md-6">
              <h3>{t('webMaintenance')}</h3>
              <p>
                {t('webMaintenanceDetail')}
              </p>
            </div>
            <div className="col-md-6">
              <img
                src="images/Website-maintainance.jpg"
                className="img-fluid"
                alt={t('webMaintenance')}
              />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Services;
