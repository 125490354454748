import React, { useEffect } from 'react';
import ReactGA from 'react-ga';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const Home = () => {
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  const { t } = useTranslation();
  
  return (
    <div>
      <Helmet>
        <title>Home - MackiTech Solutions</title>
        <meta
          name="description"
          content="MackiTech Solutions: Your partner in innovative digital solutions. We specialize in web development, augmented reality, and web applications."
        />
      </Helmet>
      <section className="jumbotron text-center">
        <div className="container transparent-bg">
          <div>
            <h1 className="display-4 text-light">
              {t('welcome')}
            </h1>
            <p className="lead text-light">
              {t('description')}
            </p>
            <Link
              className="btn btn-primary btn-lg"
              to="/services"
            >
              {t('start')}
            </Link>
          </div>
        </div>
      </section>
      <div className="container">
        <section className="py-5">
          <h2 className="text-center">{t('ourServices')}</h2>
          <div className="row mt-4">
            <div className="col-md-4 mb-4">
              <div className="card service-card">
                <img
                  src="images/web-design.jpg"
                  className="card-img-top"
                  alt={t('webDesign')}
                />
                <div className="card-body">
                  <h5 className="card-title">{t('webDesign')}</h5>
                  <p className="card-text">
                    {t('webDesignHome')}
                  </p>
                  <Link className="btn btn-secondary" to="/webdev">
                    {t('more')}
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-md-4 mb-4">
              <div className="card service-card">
                <img
                  src="images/web-application.jpg"
                  className="card-img-top"
                  alt={t('webApp')}
                />
                <div className="card-body">
                  <h5 className="card-title">{t('webApp')}</h5>
                  <p className="card-text">
                    {t('webAppHome')}
                  </p>
                  <Link className="btn btn-secondary" to="/webapp">
                    {t('more')}
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-md-4 mb-4">
              <div className="card service-card">
                <img
                  src="images/augmented-reality.jpg"
                  className="card-img-top"
                  alt={t('ar')}
                />
                <div className="card-body">
                  <h5 className="card-title">{t('ar')}</h5>
                  <p className="card-text">
                    {t('arHome')}
                  </p>
                  <Link className="btn btn-secondary" to="/ar">
                    {t('more')}
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <div className="container my-5">
        <section className="about-us">
          <div className="row">
            <div className="col-md-6">
              <h2>{t('aboutUs')}</h2>
              <p>
                {t('aboutHome1')}
              </p>
              <p>
                {t('aboutHome2')}
              </p>
            </div>
            <div className="col-md-6">
              <img
                src="images/digital-solutions.png"
                className="img-fluid"
                alt={t('aboutUs')}
              />
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default Home;
