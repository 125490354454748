import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { FaWhatsapp, FaPhone, FaEnvelope } from 'react-icons/fa';
import './Footer.css';

const Footer = () => {
  const { i18n, t } = useTranslation();

  const changeLanguage = (event) => {
    i18n.changeLanguage(event.target.value);
  };

  return (
    <footer className="text-muted py-5 bg-light">
      <div className="container">
        <div className="row">
          <div className="col-md-4">
            <h5>{t('quickLinks')}</h5>
            <ul className="list-unstyled">
              <li><Link to="/">{t('home')}</Link></li>
              <li><Link to="/services">{t('services')}</Link></li>
              <li><Link to="/about">{t('about')}</Link></li>
              <li><Link to="/contact">{t('contact')}</Link></li>
            </ul>
            <select onChange={changeLanguage} defaultValue={i18n.language}>
              <option value="en">{t('english')}</option>
              <option value="fr">{t('french')}</option>
            </select>
            <p className='py-1'></p>
          </div>
          <div className="col-md-4">
            <h5>{t('contactInfo')}</h5>
            <ul className="list-unstyled">
              <li>
                <a href="mailto:info@mackitechsolutions.com" className='no-style' title='Email'><FaEnvelope size={20} className='contact-icon' />info@mackitechsolutions.com</a>
              </li>
              <li>
                <a href={"tel:+" + t('contactPhone'.replaceAll(' ',''))} title='Phone'><FaPhone size={20} className='contact-icon' /></a><a href={"https://wa.me/+" + t('contactPhone'.replaceAll(' ',''))} target="_blank" rel="noopener noreferrer" title='WhatsApp'><FaWhatsapp size={25} className='contact-icon whatsApp' /></a>+{t('contactPhone')}
              </li>
              <li>{t('address')} {t('visitUsDetailLine1')}, {t('visitUsDetailLine2')}</li>
            </ul>
          </div>
        </div>
        
        <p className="mb-1">© MackiTech Solutions 2024</p>
        <p className="mb-0">{t('copywrite')}</p>
      </div>
    </footer>
  );
};

export default Footer;
