import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import Home from './pages/Home';
import Services from './pages/Services';
import AugmentedReality from './pages/AugmentedReality';
import WebApplication from './pages/WebApplication';
import WebDevelopment from './pages/WebDevelopment';
import About from './pages/About';
import Contact from './pages/Contact';
import CookiesConsent from './CookiesConsent';
import PrivacyPolicy from './PrivacyPolicy';
import InstallButton from './components/InstallButton'; // Import the InstallButton
import ScrollToTop from './components/ScrollToTop';

function App() {

  return (
    <div>
      <Header />
      <main role="main">
        <ScrollToTop />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/services" element={<Services />} />
          <Route path="/ar" element={<AugmentedReality />} />
          <Route path="/webapp" element={<WebApplication />} />
          <Route path="/webdev" element={<WebDevelopment />} />
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        </Routes>
      </main>
      <Footer />
      <InstallButton /> {/* Add the InstallButton here */}
      <CookiesConsent />
    </div>
  );
}

export default App;
