import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';
import App from './App';
import './i18n'; // Import the i18n configuration
import 'bootstrap/dist/css/bootstrap.min.css';
// Import Bootstrap JavaScript
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import './custom.css'; // Import custom styles

import * as serviceWorkerRegistration from './serviceWorkerRegistration'; // Ensure this is correct
import ReactGA from 'react-ga';

ReactGA.initialize('G-7S4MJD4N6K');

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <Router>
    <App />
  </Router>
);

serviceWorkerRegistration.register(); // Register the service worker