import React, { useEffect } from 'react';
import ReactGA from 'react-ga';
import { Helmet } from 'react-helmet';

const PrivacyPolicy = () => {
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);
  
  return (
    <div className="container py-5">
      <Helmet>
        <title>Privacy Policy - MackiTech Solutions</title>
        <meta
          name="description"
          content="Learn how we collect, use, and protect your information."
        />
      </Helmet>
      <section className="hero-section text-center">
        <div className="container">
          <h1>Privacy Policy</h1>
          <p>Learn how we collect, use, and protect your information.</p>
        </div>
      </section>
      <section className="privacy-policy">
        <div className="container">
          <h2>Privacy Policy</h2>
          <p>Last updated: January 1, 2024</p>

          <h3>1. Introduction</h3>
          <p>
            Welcome to MackiTech Solutions. Your privacy is important to us.
            This Privacy Policy explains how we collect, use, and protect your
            information when you visit our website.
          </p>

          <h3>2. Information We Collect</h3>
          <p>
            We collect information from you when you visit our site, register on
            our site, place an order, subscribe to our newsletter, respond to a
            survey, fill out a form, or enter information on our site.
          </p>
          <ul>
            <li>
              Personal identification information (name, email address, phone
              number, etc.)
            </li>
            <li>
              Payment information (credit card details, billing address, etc.)
            </li>
            <li>
              Technical information (IP address, browser type, operating system,
              etc.)
            </li>
          </ul>

          <h3>3. How We Use Your Information</h3>
          <p>
            We may use the information we collect from you in the following
            ways:
          </p>
          <ul>
            <li>
              To personalize your experience and to allow us to deliver the type
              of content and product offerings in which you are most interested.
            </li>
            <li>To improve our website in order to better serve you.</li>
            <li>
              To allow us to better service you in responding to your customer
              service requests.
            </li>
            <li>
              To administer a contest, promotion, survey, or other site feature.
            </li>
            <li>To quickly process your transactions.</li>
            <li>
              To send periodic emails regarding your order or other products and
              services.
            </li>
          </ul>

          <h3>4. How We Protect Your Information</h3>
          <p>
            Your privacy is important to us. We are committed to protecting your
            personal information and maintaining a secure environment for our
            users. Here are the measures we take to ensure the safety of your
            information:
          </p>

          <h4>Data Encryption</h4>
          <p>
            We use industry-standard encryption protocols to protect your data
            during transmission and storage. This ensures that any information
            you share with us is securely transmitted over the internet and
            stored in our systems.
          </p>

          <h4>Secure Servers</h4>
          <p>
            Our servers are hosted in a secure environment with access controls,
            firewalls, and other advanced security technologies to prevent
            unauthorized access. We regularly update our systems to address
            potential vulnerabilities and ensure the highest level of security.
          </p>

          <h4>Access Control</h4>
          <p>
            Access to your personal information is restricted to authorized
            personnel who need to know this information to provide services to
            you. We have implemented strict access control measures to ensure
            that your data is only accessible to those with the appropriate
            permissions.
          </p>

          <h4>Regular Security Audits</h4>
          <p>
            We conduct regular security audits and assessments to identify and
            mitigate potential risks. Our security team is continuously
            monitoring our systems for suspicious activity and vulnerabilities
            to maintain the integrity of our security measures.
          </p>

          <h4>Employee Training</h4>
          <p>
            All employees undergo regular training on our security policies and
            procedures to ensure they understand the importance of protecting
            your information and are equipped with the knowledge to do so
            effectively.
          </p>

          <h4>Data Anonymization</h4>
          <p>
            When possible, we anonymize your data to further protect your
            privacy. This means that any information that could identify you
            personally is removed or obscured, making it difficult for
            unauthorized individuals to link the data back to you.
          </p>

          <h4>Incident Response</h4>
          <p>
            In the unlikely event of a data breach or security incident, we have
            an incident response plan in place to promptly address the issue. We
            will notify affected users and take all necessary steps to mitigate
            any potential harm.
          </p>

          <h4>Third-Party Service Providers</h4>
          <p>
            We carefully select third-party service providers who adhere to
            stringent security standards. Any third parties we work with are
            required to comply with our privacy policies and are obligated to
            protect your information.
          </p>

          <p>
            While we strive to implement the best security practices, please be
            aware that no system is completely immune to security risks. We
            encourage you to take your own precautions to protect your personal
            information, such as using strong passwords and keeping your login
            credentials confidential.
          </p>

          <p>
            If you have any questions or concerns about the security of your
            information, please contact us at{" "}
            <a href="mailto:info@mackitechsolutions.com">
              info@mackitechsolutions.com
            </a>
            .
          </p>
        </div>
      </section>
    </div>
  );
};

export default PrivacyPolicy;
