import React, { useEffect } from 'react';
import ReactGA from 'react-ga';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

const Services = () => {
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  const { t } = useTranslation();
  
  return (
    <div className="container py-5">
      <Helmet>
        <title>{t('webAppDev')} - MackiTech Solutions</title>
        <meta
          name="description"
          content="MackiTech Solutions specializes in developing robust web applications. Our solutions are scalable, secure, and tailored to enhance your business operations."
        />
      </Helmet>
      <section className="hero-section text-center" id="web-app">
        <div className="container">
          <h1>{t('webAppDev')}</h1>
          <p>
            {t('webAppDevDetail')}
          </p>
        </div>
      </section>
      <section className="service-details">
        <div className="container">
          <h2>{t('webAppDevTitle')}</h2>
          <p>
            {t('webAppDevTitleDetail')}
          </p>

          <div className="row">
            <div className="col-md-6">
              <h3>{t('customWebAppDev')}</h3>
              <p>
                {t('customWebAppDevDetail')}
              </p>
            </div>
            <div className="col-md-6">
              <img
                src="images/web-application.jpg"
                className="img-fluid"
                alt={t('customWebAppDevDetail')}
              />
            </div>
          </div>

          <div className="row mt-5">
            <div className="col-md-6 order-md-2">
              <h3>{t('pwa')}</h3>
              <p>
                {t('pwaDetail')}
              </p>
            </div>
            <div className="col-md-6 order-md-1">
              <img
                src="images/progressive_web_app.jpg"
                className="img-fluid"
                alt={t('pwa')}
              />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Services;
