import React, { useState, useEffect } from 'react';
import Cookies from 'js-cookie';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import './CookiesConsent.css'; // Import CSS file for styling the dialog

const CookiesConsent = ({ cookieName = 'cookiesConsent', cookieExpiresInDays = 365 }) => {
  const [showDialog, setShowDialog] = useState(false);

  // Check if the user has previously consented to cookies
  useEffect(() => {
    const hasConsented = Cookies.get(cookieName);
    if (!hasConsented) {
      // If the user has not consented, show the dialog
      setShowDialog(true);
    }
  }, [cookieName]);

  const handleAccept = () => {
    // Set a cookie to indicate the user has consented to cookies
    Cookies.set(cookieName, 'true', { expires: cookieExpiresInDays });
    // Hide the dialog
    setShowDialog(false);
  };

  const handleDecline = () => {
    // Hide the dialog
    setShowDialog(false);
    // You can add additional logic here, such as blocking certain features or analytics
  };

  return (
    <div>
      {showDialog && (
        <div className="cookies-consent-dialog">
          <p>We use cookies to ensure you get the best experience on our website. By continuing to browse, you consent to our use of cookies. <Link to="/privacy-policy">Learn more</Link>.
          </p>
          <div className="buttons-container">
            <button className="accept-btn btn btn-primary" onClick={handleAccept}>Accept</button>
            <button className="decline-btn btn btn-primary" onClick={handleDecline}>Decline</button>
          </div>
        </div>
      )}
    </div>
  );
};

CookiesConsent.propTypes = {
  cookieName: PropTypes.string,
  cookieExpiresInDays: PropTypes.number,
};

export default CookiesConsent;
