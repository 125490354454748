import React, { useEffect } from 'react';
import ReactGA from 'react-ga';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const Services = () => {
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  const { t } = useTranslation();
  
  return (
    <div className="container py-5">
      <Helmet>
        <title>Services - MackiTech Solutions</title>
        <meta name="description" content="Explore our comprehensive range of digital solutions at MackiTech Solutions. We offer web development, augmented reality experiences, and custom web applications." />
      </Helmet>
      <section className="service-section services-overview">
        <div className="container">
          <h1 className="text-center">{t('ourServices')}</h1>
          <p className="lead text-center">{t('servicesDetail')}</p>
          <div className="row">
            <div className="col-md-4 my-2">
              <div className="card service-card">
                <img src="images/web-design.jpg" className="card-img-top" alt={t('servicesWebDev')} />
                <div className="card-body">
                  <h5 className="card-title">{t('servicesWebDev')}</h5>
                  <p className="card-text">{t('servicesWebDevDetail')}</p>
                  <Link className="btn btn-primary" to="/webdev">{t('more')}</Link>
                </div>
              </div>
            </div>
            <div className="col-md-4 my-2">
              <div className="card service-card">
                <img src="images/web-application.jpg" className="card-img-top" alt={t('servicesWebApp')} />
                <div className="card-body">
                  <h5 className="card-title">{t('servicesWebApp')}</h5>
                  <p className="card-text">{t('servicesWebAppDetail')}</p>
                  <Link className="btn btn-primary" to="/webapp">{t('more')}</Link>
                </div>
              </div>
            </div>
            <div className="col-md-4 my-2">
              <div className="card service-card">
                <img src="images/augmented-reality.jpg" className="card-img-top" alt={t('servicesAR')} />
                <div className="card-body">
                  <h5 className="card-title">{t('servicesAR')}</h5>
                  <p className="card-text">{t('servicesARDetail')}</p>
                  <Link className="btn btn-primary" to="/ar">{t('more')}</Link>
                </div>
              </div>
            </div>
          </div>
          </div>
      </section>
    </div>
  );
};

export default Services;
