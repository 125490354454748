import React, { useEffect } from 'react';
import ReactGA from 'react-ga';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

const About = () => {
  
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  const { t } = useTranslation();

  return (
    <div className="container py-5">
      <Helmet>
        <title>{t('aboutUs')} - MackiTech Solutions</title>
        <meta
          name="description"
          content="Learn about MackiTech Solutions, a leading provider of innovative digital solutions. Meet our team and discover our mission and vision."
        />
      </Helmet>
      <section className="about-us">
        <div className="container">
          <h1 className="text-center">{t('aboutUs')}</h1>
          <p className="lead text-center">
            {t('aboutDetail')}
          </p>
          <div className="row">
            <div className="col-md-6">
              <h2>{t('mission')}</h2>
              <p>
                {t('missionDetail')}
              </p>
            </div>
            <div className="col-md-6">
              <img
                src="images/collaboration.jpg"
                className="img-fluid"
                alt={t('mission')}
              />
            </div>
          </div>
          <div className="row mt-5">
            <div className="col-md-6">
              <img
                src="images/futuristic.jpg"
                className="img-fluid"
                alt={t('vision')}
              />
            </div>
            <div className="col-md-6">
              <h2>{t('vision')}</h2>
              <p>
                {t('visionDetail')}
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="py-5">
        <div className="container">
          <h2 className="text-center">{t('values')}</h2>
          <div className="row mt-5">
            <div className="col-md-4">
              <h5>{t('innovation')}</h5>
              <p>
                {t('innovationDetail')}
              </p>
            </div>
            <div className="col-md-4">
              <h5>{t('quality')}</h5>
              <p>
                {t('qualityDetail')}
              </p>
            </div>
            <div className="col-md-4">
              <h5>{t('integrity')}</h5>
              <p>{t('integrityDetail')}</p>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default About;
