import React, { useEffect, useState } from 'react';
import ReactGA from 'react-ga';
import { Helmet } from 'react-helmet';
import { FaWhatsapp, FaPhone, FaEnvelope } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';

function getFormData(form) {
  const elements = form.elements;
  let honeypot;

  const fields = Object.keys(elements).filter((k) => {
    if (elements[k].name === "honeypot") {
      honeypot = elements[k].value;
      return false;
    }
    return true;
  }).map((k) => {
    if(elements[k].name !== undefined) {
      return elements[k].name;
    } else if(elements[k].length > 0){
      return elements[k].item(0).name;
    }
  }).filter((item, pos, self) => {
    return self.indexOf(item) === pos && item;
  });

  const formData = {};
  fields.forEach((name) => {
    const element = elements[name];

    formData[name] = element.value;

    if (element.length) {
      const data = [];
      for (let i = 0; i < element.length; i++) {
        const item = element.item(i);
        if (item.checked || item.selected) {
          data.push(item.value);
        }
      }
      formData[name] = data.join(', ');
    }
  });

  formData.formDataNameOrder = JSON.stringify(fields);
  formData.formGoogleSheetName = form.dataset.sheet || "responses";
  formData.formGoogleSendEmail = form.dataset.email || "";

  return {data: formData, honeypot: honeypot};
}

function Contact() {

  const { t } = useTranslation();

  const [buttonText, setButtonText] = useState(t('submit'));

  function handleSubmit(event) {
    event.preventDefault();
    const form = event.target;
    const formData = getFormData(form);
    const data = formData.data;

    if (formData.honeypot) {
      return false;
    }

    setButtonText(t('pleaseWait'));

    const url = form.action;
    const xhr = new XMLHttpRequest();
    xhr.open('POST', url);
    xhr.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
    xhr.onreadystatechange = function() {
      if (xhr.readyState === 4 && xhr.status === 200) {
        form.reset();
        setButtonText(t('submit'));
      }
    };

    const encoded = Object.keys(data).map((k) => {
      return encodeURIComponent(k) + "=" + encodeURIComponent(data[k]);
    }).join('&');

    xhr.send(encoded);
  }

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  return (
    <div className="container py-5">
      <Helmet>
        <title>{t('contactUs')} - MackiTech Solutions</title>
        <meta
          name="description"
          content="Get in touch with MackiTech Solutions. We\'re here to answer your questions and provide the support you need. Contact us today."
        />
      </Helmet>
      <section className="text-center">
        <div className="container">
          <h1>{t('contactUs')}</h1>
          <p className="lead text-center">
            {t('contactDetail')}
          </p>
        </div>
      </section>
      <section className="contact-form">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 mt-5">
              <h3>{t('sendMessage')}</h3>
              <form
                onSubmit={handleSubmit}
                method="post"
                action="sendMail.php"
              >
                <div className="mb-3">
                  <label htmlFor="name" className="form-label">
                    {t('name')}
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    name="name"
                    id="name"
                    placeholder={t('enterName')}
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="email" className="form-label">
                    {t('email')}
                  </label>
                  <input
                    type="email"
                    className="form-control"
                    name="email"
                    id="email"
                    placeholder={t('enterEmail')}
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="subject" className="form-label">
                    {t('subject')}
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    name="subject"
                    id="subject"
                    placeholder={t('enterSubject')}
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="message" className="form-label">
                    {t('message')}
                  </label>
                  <textarea
                    className="form-control"
                    name="message"
                    id="message"
                    rows="5"
                    placeholder={t('enterMessage')}
                  ></textarea>
                </div>
                <button type="submit" className="btn btn-primary" disabled={buttonText === t('pleaseWait')}>{buttonText}</button>
              </form>
            </div>
            <div className="col-lg-6 mt-5">
              <h2>{t('visitUs')}</h2>
              <p>{t('visitUsDetail')}</p>
              <p>{t('visitUsDetailLine1')}</p>
              <p>{t('visitUsDetailLine2')}</p>
              <h3>{t('contactInfo')}</h3>
              <p>
                <a href="mailto:info@mackitechsolutions.com" className='no-style' title='Email'><FaEnvelope size={20} className='contact-icon' />info@mackitechsolutions.com</a>
              </p>
              <p>
                <a href={"tel:+" + t('contactPhone'.replaceAll(' ',''))} title='Phone'><FaPhone size={20} className='contact-icon' /></a><a href={"https://wa.me/+" + t('contactPhone'.replaceAll(' ',''))} target="_blank" rel="noopener noreferrer" title='WhatsApp'><FaWhatsapp size={25} className='contact-icon whatsApp' /></a>+{t('contactPhone')}
              </p>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Contact;
